import './Header.css';

export const Header = () =>(
<header className="App-header">
<h1 style={{ fontWeight: 100, lineHeight: "20px" }}>
  AvoDo
  <br />
  <span className="spanH1Style">Todo App</span>
</h1>
</header>
)