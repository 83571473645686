import "./Loader.css";
import React from "react";

function Loader() {
  return (
    <div className="lds-container">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
      <p>Loading...</p>
    </div>
  )
}

export default Loader;
