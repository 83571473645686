import "./Footer.css";

export const Footer = ({userEmail, handleSignOut}) => {
  return (
    <footer className="mainFooter">
      <p style={{ fontSize: "1rem", opacity: 0.7 }}>
        Logged in as:
        <br />
        {userEmail}
      </p>
      <button
        onClick={handleSignOut}
        className="mainBtn signOutBtn"
        style={{ marginBottom: "2vh" }}
      >
        Sign out
        <span role="img" aria-label="Peace emoji">
          ✌️
        </span>
      </button>
  </footer>
  )
}