export default function AppDescription() {
  return (
    <>
      <div className="cstmDivider cstmDividerPadding"></div>
      <h2>A simple todo app</h2>
      <p>
        <span role="img" aria-label="Information desk man emoji">
          💁‍♂️
        </span>
        {" "}This is a simple todo web app. Make an account, start todoing. <br />
        <br />
        <span
          role="img"
          aria-label="Backhand index finger pointing right emoji"
        >
          👉
        </span>
        {" "}Use emojis{" "}
        <span role="img" aria-label="unicorn emoji">
          🦄
        </span>{" "}
        <span role="img" aria-label="avocado emoji">
          🥑
        </span>{" "}
        <span role="img" aria-label="banana emoji">
          🍌
        </span>{" "}
        and have fun{" "}
        <span
          role="img"
          aria-label="grinning face with squinting eyes emoji"
        >
          😁
        </span>
      </p>
    </>
  )
}
