import React, { useState } from 'react';
import useTodoItems from './useTodoItems'; // Adjust the import path as necessary

function TodoList({ userInfo }) {
  const { todoItems, addTodoItem, removeTodoItem, toggleTodoItem } = useTodoItems(userInfo.uid);
  const [inputVal, setInputVal] = useState("");

  const addItem = (e) => {
    e.preventDefault();
    if (inputVal) {
      addTodoItem(inputVal);
      setInputVal("");
    }
  };

  return (
    <>
      <form onSubmit={addItem} id="todoListForm">
        <input
          name="addItemInput"
          onChange={e => setInputVal(e.target.value)}
          value={inputVal}
          placeholder="Add item...  📦  🛠 📱"
          className="addItemInput"
        ></input>
        <button className="mainBtn signOutBtn circleBtn">ADD</button>
      </form>
      {todoItems.map(item => (
        <div className="todoList" key={item.id}>
          <input
            type="checkbox"
            id={item.id}
            checked={item.done}
            onChange={() => toggleTodoItem(item.id, !item.done)}
          />
          <label htmlFor={item.id}>{item.text}</label>
          <button
            className="closeButton"
            onClick={() => removeTodoItem(item.id)}
          >⤬</button>
        </div>
      ))}
    </>
  );
}

export default TodoList;