import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import db from '../firebase';
import AppDescription from "./AppDescription";

const userActions = {
  login: {
    title: "Login",
    topBtnTxt: "Create Account",
    submitBtnTxt: "Login",
    action: signInWithEmailAndPassword,
    autoComplete: "current-password"
  },
  createAccount: {
    title: "Create An Account",
    topBtnTxt: "Already a user, go to login",
    submitBtnTxt: "Generate account",
    action: createUserWithEmailAndPassword,
    autoComplete: "new-password"
  }
};

function HandleUser() {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPswrd, setInputPswrd] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [currentAction, setCurrentAction] = useState("login");

  const auth = getAuth();

  const toggleUserAction = () => {
    setCurrentAction(currentAction === "login" ? "createAccount" : "login");
    setApiMessage("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setApiMessage("");
    const action = userActions[currentAction].action;

    try {
      const userCredential = await action(auth, inputEmail, inputPswrd);
      if (currentAction === "createAccount") {
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), { userId: user.uid, email: user.email });
      }
    } catch (error) {
      console.error(`${currentAction} error:`, error);
      setApiMessage("An error occurred. Please try again.");
    }
  };

  const updateField = setter => e => setter(e.target.value);

  return (
    <div className="handleUserCard">
      <button className="redBtn mainBtn" onClick={toggleUserAction}>
        {userActions[currentAction].topBtnTxt}
      </button>
      <h1>{userActions[currentAction].title}</h1>
      <form onSubmit={handleFormSubmit} id="userForm" autoComplete="on">
        <FormField id="email" label="Email" type="email" value={inputEmail} autoComplete="username" onChange={updateField(setInputEmail)} />
        <FormField id="password" label="Password" type="password" value={inputPswrd} autoComplete={userActions[currentAction].autoComplete} onChange={updateField(setInputPswrd)} />
        {apiMessage && <div style={{ color: "#E194FF", fontSize: "12px" }}>{apiMessage}</div>}
        <button type="submit" className="mainBtn greenBtn">{userActions[currentAction].submitBtnTxt}</button>
      </form>
      <AppDescription />
    </div>
  );
}

function FormField({ id, label, type, value, autoComplete, onChange }) {
  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <input id={id} type={type} value={value} aria-required="true" autoComplete={autoComplete} onChange={onChange} />
    </div>
  );
}

export default HandleUser;
