import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "./AuthContext";
import "./App.css";
import HandleUser from "./components/HandleUser";
import TodoList from "./components/TodoList";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";

function App() {
  const { user, loading } = useAuth();
  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Sign-out error:", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <main>
        {!user ? <HandleUser /> : <TodoList userInfo={user} />}
      </main>
      {user && <Footer userEmail={user.email} handleSignOut={handleSignOut} />}
    </>
  );
}

export default App;