import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, deleteDoc, updateDoc, Timestamp, doc } from 'firebase/firestore';
import db from '../firebase';

function useTodoItems(userId) {
  const [todoItems, setTodoItems] = useState([]);

  useEffect(() => {
    if (!userId) return;
    const q = query(collection(db, "todolist"), where("userId", "==", userId), orderBy("createdEditedTime", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => {
          if (a.done === b.done) {
            return b.createdEditedTime.toMillis() - a.createdEditedTime.toMillis(); // Sort by timestamp if both are done or not done
          }
          return a.done ? 1 : -1; // Keep done items at the bottom
        });
      setTodoItems(items);
    });

    return () => unsubscribe();
  }, [userId]);

  const addTodoItem = async (text) => {
    await addDoc(collection(db, "todolist"), {
      text,
      done: false,
      createdEditedTime: Timestamp.now(),
      userId
    });
  };

  const removeTodoItem = async (id) => {
    await deleteDoc(doc(db, "todolist", id));
  };

  const toggleTodoItem = async (id, done) => {
    const todoListItemRef = doc(db, "todolist", id);
    await updateDoc(todoListItemRef, {
      done,
      createdEditedTime: Timestamp.now(),
    });
  };

  return { todoItems, addTodoItem, removeTodoItem, toggleTodoItem };
}

export default useTodoItems;